<template>
    <vs-popup class="booking_popup" title="Ajout réservation" :active.sync="popupActive">

        <template v-if="coller==true">
            <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-w="11">
                    <vs-button color="primary" type="filled" class="w-full" v-on:click="fcoller()">{{ coller_name }}</vs-button><br/>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1" style="padding-left:5px;">
                    <vs-icon icon="delete" color="primary" size="large" class="cursor" v-on:click="fclear()"></vs-icon>
                </vs-col>
            </vs-row>
        </template>

        <template v-else-if="move==true">
            <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-w="11">
                    <vs-button color="primary" type="filled" class="w-full" v-on:click="fmove()">Déplacer</vs-button><br/>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1" style="padding-left:5px;">
                    <vs-icon icon="delete" color="primary" size="large" class="cursor" v-on:click="fclear()"></vs-icon>
                </vs-col>
            </vs-row>
        </template>

        <template v-else>
            <vs-button :color="getColor('essai')" type="filled" class="w-full" v-on:click="selectAdd('e')">Séance essai</vs-button><br/>
            <br/>
            <vs-button :color="getColor('fessai')" type="filled" class="w-full" v-on:click="selectAdd('c')">Séance contractuelle</vs-button><br/>
            <br/>
            <vs-button :color="getColor('actif')" type="filled" class="w-full" v-on:click="selectAdd('m')">Séance membre</vs-button><br/>
            <br/>
            <br/>
            <vs-button color="danger" type="filled" class="w-full" v-on:click="selectAdd('b')">Non-réservable</vs-button><br/>
        </template>

    </vs-popup>
</template>

<script>
import Member  from '@/database/models/member'
import Utils from '@/assets/utils/utils'

export default {
  data() {
    return {
      popupActive: false,
      coller: false,
      move: false,
      coller_name:'',
    }
  },
    methods:{
        /* OPEN POPUP */
        openPopup( coller=false, move=false ){

            //mode copier
            this.coller = false
            if(coller)
                this.coller = true

            //mode coller
            this.move = false
            if(move)
                this.move = true

            //activer la popup
            this.popupActive = true

            //si collé calcule du nom
            if(this.coller)
            Member.getMemory( coller.uid, (member)=>
            {
                this.coller_name = Utils.StrCapital(member.first_name+' '+member.last_name)
            })
        },

        getColor( type )
        {
            return Member.color[ type ]
        },

        selectAdd( type )
        {
            this.popupActive = false
            this.$emit('select', type)
        },

        fcoller()
        {
            this.popupActive = false
            this.$emit('coller')
        },
        fclear()
        {
            this.coller = false
            this.move   = false
            this.$emit('clear')
        },


        fmove()
        {
            this.popupActive = false
            this.$emit('move')
        },
    }
}
</script>