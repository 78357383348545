<template>
    <vs-popup class="bookingAdd_popup" title="Ajout réservation" :active.sync="popupActive">
        <template v-if="chargement" >
            <h2 class="text-center center">Création séance en cours ...</h2>
        </template>
        <template v-else >
            <vs-row>
                <vs-col vs-xs="12" vs-lg="12">
                    <vs-table v-model="selected" pagination max-items="5" search :data="allMember" @selected="addResa" stripe >
                        <!-- header table -->
                        <template slot="thead">
                            <vs-th>Prénom Nom</vs-th>
                            <vs-th>Crédit/Utilisé</vs-th>
                            <vs-th>Créance</vs-th>
                        </template>

                        <!--Body table-->
                        <template slot-scope="{data}">
                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr].first_name">
                                    {{ StrCapital(data[indextr].first_name)+' '+StrCapital(data[indextr].last_name) }}
                                </vs-td>
                                <vs-td :data="data[indextr].uid">
                                    <span>{{ data[indextr].credit }} / {{ data[indextr].credit_used }}</span>
                                </vs-td>
                                <vs-td>
                                    <span>{{ data[indextr].dif.toFixed(2) }} €</span>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>

                    <template v-if="nb_resa>1" >
                        <vs-button color="primary" type="border" class="w-full"><b>{{ selected.length }}</b> / {{ nb_resa }} Membre(s)</vs-button><br/>
                        <br/>
                    </template>
                </vs-col>
            </vs-row>
            <vs-divider/>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                    <div></div>
                    <vs-button color="warning" type="filled" v-on:click="annuler" icon-pack="feather">Annuler</vs-button>
                    <!--<vs-button color="success" type="filled" v-on:click="add(1)" icon-pack="feather" icon="icon-plus">Ajouter</vs-button>-->
                </vs-col>
            </vs-row>
        </template>
    </vs-popup>
</template>

<style lang="scss">
.bookingAdd_popup
{
    .vs-col:first-child{
        padding-right:30px;
    }
    .con-input-search{
        margin-right:auto;
        margin-left:auto;
    }
    .vs-table--search{
        max-width:500px;
    }
    .vs-table--search-input{
        border: 2px solid rgba(0, 0, 0, 0.6) !important;
        border-radius:0px;
        border-top:none !important;
        border-right:none !important;
        border-left:none !important;
        
        margin-left:10px;
        font-size:1.5em;
    }
    @media screen and (min-width: 640px) {
        .vs-table--search-input{
            width:500px;
        }
    }
    .vs-table--search .vs-icon{
        font-size: 1.5em;
    }
}
</style>

<script>
import Credit from '@/assets/utils/credit'
import Utils from '@/assets/utils/utils';
import Fdate from '@/assets/utils/fdate';

import Member  from '@/database/models/member';
import Booking  from '@/database/models/booking';

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            chargement:false,

            allMember:[],
            selected:[],

            add_machine_uid:null,

            allHour:[],
            add_hour:'XX:XX',
            add_repeat:1,
            date:null,

            type_filtre_select:'m',
            nb_resa:0,
        }
    },
    watch:{
        $route (){
            
        },
    },
    methods:{
        openPopup( date, machine, hour, type, member=null )
        {
            this.popupActive = true
            this.chargement = false;
            this.selected = []

            this.date            = date
            this.add_hour        = hour
            this.add_machine_uid = machine

            //Si membre selected
            if(member)
                this.selected = member

            //set type
            if(type=="c")
                this.type_filtre_select={label:'Contractuel',value:'e'}
            if(type=="m")
                this.type_filtre_select={label:'Membre',value:'m'}

            //Load member
            this.allMember = []
            Member.getTabAllMemory(async(members)=>
            {
                let tab = []
                for( var i=0; i<members.length; i++)
                if( members[i].type == this.type_filtre_select.value)
                {
                    //calcule crédit
                    let tab_credit = await Credit.getCreditFiltre( members[i].uid, 'global' );
                    members[i].credit      = tab_credit.credit;
                    members[i].credit_used = tab_credit.used;
                    //calcule creance
                    members[i].dif         = await Credit.getMemberCreance( members[i].uid );

                    //add
                    tab.push(members[i])
                }
                this.allMember = tab
                //console.log( this.allMember )
            })
        },
        StrCapital( msg )
		{
			return Utils.StrCapital(msg);
        },
        add( mode, map_posi=0)
        {
            let foncAdd=( elem, date )=>
            {
                //si plus de crédit
                if(elem.type == 'm' )
                if(elem.credit < 1 )
                if( !window.confirm("Ce membre ne posséde plus de crédit, voulez-vous continuer ?") )
                    return;

                
                this.chargement = true;
                Booking.add({
                    date     : Fdate.getISO_8601(date),
                    machine  : this.add_machine_uid,
                    member   : elem.uid,
                    map      : map_posi,
                })
                .then(()=>{
                    this.popupActive = false
                    //mise a jour crédit
                    elem.credit--;
                    elem.credit_util++;
                    //Event refresh
                    this.$emit('refresh')
                })
                .catch(()=>{
                    this.popupActive = false
                })
            }

            //Build date
            let hour = this.add_hour.split(':')
            let d = new Date(this.date)
            d.setHours(hour[0])
            d.setMinutes(hour[1])
            d.setSeconds(0)

            //-------------------------------------------
            //Mode unique ou multiple
            if( this.selected[0] == undefined )
                foncAdd( this.selected, d )
            else
            for( var i=0; i<this.selected.length; i++)
                foncAdd( this.selected[i], d )
        },

        addResa()
        {
            this.add(1)
        },
        annuler()
        {
            this.popupActive = false
        },


        /* Déplacer une séance*/
        move( uid, machine, date, map=undefined )
        {
            Booking.getMemoryClone( uid, (data)=>
            {
                data.date    = date;
                data.machine = machine;
                delete data.createAt;

                if(map != undefined)
                    data.map = map;

                Booking.update( uid, data )
                .then(()=>
                {
                    //reload tout le booking pour eviter probléme de mémoire !!!
                    Booking.load(()=>{
                        //Event refresh
                        this.$emit('refresh')
                    })
                })
            });
            this.popupActive = false
        },

    }
}

</script>