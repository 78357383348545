<template>
    <div class="bookingMatriciel">

        <template v-if="!table.body.length">
            <h2 class="center">Aucun créneau aujourd'hui.</h2>
        </template>
        <template v-else>
            <table class="w-full">
                <thead>
                    <tr>
                        <th class="noBorder" style="width:50px !important;"></th>
                        <th class="noBorder" style="min-width:120px;" v-for="index in table.head" :key="index.uid">{{ index.name }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(index,idx) in table.body" :key="index.body">

                        <template v-if="idx==0">
                            <td class="print_hour"><span style="padding-top:10px;">{{ index.hour }}</span></td>
                        </template>
                        <template v-else>
                            <td class="print_hour"><span>{{ index.hour }}</span></td>
                        </template>


                        <td v-for="td in index.td" :key="td.uid" :rowspan="td.rowspan">

                            <template v-if="td.fermeture==1">
                                <slot name="closing" v-bind:closing="td"></slot>
                            </template>
                            <template v-else>
                                <template v-if="td.lock">
                                    <slot name="lock" v-bind:lock="td"></slot>
                                </template>

                                <template v-else>
                                    <!-- plus de résa que d'emplacement -->
                                    <template v-if="td.member.length >= td.nb_resa" >
                                        <div v-for="p in td.member.length" :key="p" >
                                            <template v-if="td.member[ p-1 ] != undefined">
                                                <slot name="member" v-bind:member="td.member[ p-1 ]"></slot>
                                            </template>
                                        </div>
                                    </template>
                                    <!-- mode normal -->
                                    <template v-else>
                                        <div v-for="index in td.nb_resa" :key="index" >
                                            <template v-if="td.member[ index-1 ] != undefined">
                                                <slot name="member" v-bind:member="td.member[ index-1 ]"></slot>
                                            </template>

                                            <template v-else-if="td.extend>0 && index==td.nb_resa">
                                                <slot name="add" v-bind:add="td"></slot>
                                            </template>
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
    </div>
</template>

<style lang="scss" scoped>

.bookingMatriciel
{
    background-color:white;
}
table
{
    width:100%;
    overflow-wrap: break-word;
    border-collapse:collapse;

    td:first-child
    {
        border:none;
        width: 50px !important;
        /*vertical-align: text-top;*/
        vertical-align: middle;
    }
    td
    {
        text-align: center;
        vertical-align: middle;
        font-size: 13px;
        padding: 0 !important;
        vertical-align:middle;
        width: 31% !important;
        height:60px;

        border: 1px solid #dbdbdb;
        /*border-top:none;
        border-left:none;
        border-right:none;*/
    }
    th
    {
        text-align: center;
        font-size: 14px;
        padding: 0 0 0.75rem 0;
        padding-top:25px;

        background: white;
        top: 70px; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);

        @media only screen and (min-width: 901px)//Eviter un bug en mode mobile du sticky
        {
            position: sticky;
        }
    }
    .col-hor {
        min-width: 40px;
    }
    .col-mach {
        min-width: 100px;
    }
    .noBorder{
        border:none;
    }

    .print_hour span{
       display:block;
       margin-top:-40px !important;
       z-index:99999999;
    }
}
</style>

<script>
import Fdate from '@/assets/utils/fdate'

import Machine from '@/database/models/machine'
import Booking from '@/database/models/booking'
import bookingHour from '@/database/models/booking_hour'
import bookingClose from '@/database/models/booking_close'
//import Utils from '@/assets/utils/utils';


export default {
    props: {
        date:null,
    },
    data(){
        return{
            table:{
                body:[],
                head:[],
            },
            compte:0,
        }
    },
    watch:{
        $route (){
        },
        date(){
            this.build()
        },
    },
    mounted:function(){
        this.build()
    },
    methods:{
        click(){
            //console.log('click')  
        },
        build(){
            this.table.body = []
            this.table.head = []

            GetDataBuild( this.date, (data)=>{
                this.table = BuildHTML( data )
            })
        }
    }
}


function GetDataBuild( date, callback )
{
    let tab = []
    let compte = 0;
    let nb = 0;

    let day = date.getDay()

    let fonc = function( data )
    {
        data.hour     = []
        data.end_flag = []
        data.close    = []

        //hour
        bookingHour.getTabAllMemoryKey(data.uid,function(list){

            for( var p=0; p<list.length;p++)
            if(list[p].day == day)
            {
                let rep = list[p]
                data.hour.push( rep )

                //add end flag heur
				if( rep.flag == 'e')
					data.end_flag.push( rep.hour );
            }

            //close
            bookingClose.getTabAllMemoryKey(data.uid,function(list){

                for( var c=0; c<list.length;c++)
                {
                    let d = new Date(list[c].date)
                    if(d.getDate() == date.getDate())
                    if(d.getMonth() == date.getMonth())
                    if(d.getFullYear() == date.getFullYear())
                        data.close.push(  list[c] )
                }

                //-----------------------------
                //END
                tab.push( data )
                compte++
                if(compte>=nb)
                    callback(tab)
                //-----------------------------
            })
        })
    }

    //booking journé
    Booking.getTabAllMemoryKey( Fdate.getFullDateMin(date, true), function(lbooking)
    {
        //get all machine
        Machine.getTabAllMemory(function(list_machine)
        {
            let machine = []

            let foncAddMachine = function( bufMachine )
            {
                //capacité max
                bufMachine.max = bufMachine.booking
                //add booking
                bufMachine.nb_resa = bufMachine.booking;
                bufMachine.booking = []
                for(let lbook=0; lbook<lbooking.length; lbook++)
                if( lbooking[lbook].machine == bufMachine.uid)
                    bufMachine.booking.push( lbooking[lbook] )
                //Add
                machine.push( bufMachine )
            }

            //filtre machine et add booking
            for( var f in list_machine )
            if(list_machine[f].type=="m")
            if(list_machine[f].active)
                foncAddMachine( JSON.parse(JSON.stringify(list_machine[f])) )

            //Chargement all data
            nb = machine.length
            for( var i in machine )
            if(machine[i].type=="m")
                fonc( machine[i] )
        })
    })
}


function BuildHTML( data )
{
    let ret_html = {};
    ret_html.head = [];
    ret_html.body = [];

	//Etape 0: trier machine par nom
    data.sort(function(a, b)//trier valeur ( ATTENTION CREE DES DOUBLONS )
    {
        var ca  = a.name.toUpperCase();
        var cb  = b.name.toUpperCase();
        return ('' + ca).localeCompare(cb);
    });

    //Etape 1: Création header
    for(var i=0; i<data.length; i++)
        ret_html.head.push({'uid':data[i].uid, 'name':data[i].name})

    //Etape 2: Calcule des crénaux horaires
    let tab_hour = [];
    for(i=0; i<data.length; i++)//chaque machine
    for(var p=0; p<data[i].hour.length; p++)//chaque heure
    if( tab_hour.indexOf(data[i].hour[p].hour) == -1 )//enlever doublons
        tab_hour.push( data[i].hour[p].hour );

    //chaque machine add réservation
    for(i=0; i<data.length; i++)
    for(p=0; p<data[i].booking.length; p++)
    {
        let buf_date = Fdate.getHeure(data[i].booking[p].date)
        if( tab_hour.indexOf(buf_date) == -1 )
            tab_hour.push( buf_date );
    }

    //Etape 3 : triage des dates par heure
    tab_hour.sort(function(a, b)
    {
        let time_a = (new Date("2010/01/01 "+a+':00')).getTime();
        let time_b = (new Date("2010/01/01 "+b+':00')).getTime();
        return time_a - time_b;
    });
    //tab_hour.push('20:00');//vide pour dernier crénaux journer

    //Etape 4 : machine heure buffer
    for(i=0; i<data.length; i++)//chaque machine
    {
        var buffer = [];
        for(p=0; p<data[i].hour.length; p++)//chaque heure
        if( buffer.indexOf(data[i].hour[p].hour) == -1 )//enlever doublons
            buffer.push( data[i].hour[p].hour );

        buffer.sort(function(a, b)
        {
            let time_a = (new Date("2010/01/01 "+a+':00')).getTime();
            let time_b = (new Date("2010/01/01 "+b+':00')).getTime();
            return time_a - time_b;
        });
        data[i].hour_buffer = buffer;
    }

    //Etape 5: création body
    for( i=0; i<tab_hour.length; i++)
    {
        var tab_buffer     = {}
        tab_buffer.hour    = tab_hour[i]
        tab_buffer.rowspan = 1
        tab_buffer.td      = []

        //heure
        var heure = tab_hour[i];
        tab_buffer.hour = heure

        //Machine heure
        for( p=0; p<data.length; p++ )
        {
            var ok = false;
            var celulle = {
                uid       : heure+'/'+data[p].uid,
                machine   : data[p].uid,
                nb_resa   : data[p].nb_resa,
                hour      : heure,
                essai     : 0,
                cessai    : 0,
                extend    : 0,
                fermeture : 0,
                lock      : 0,
                member    : [],
            }

            var d_d = data[p].hour_buffer.indexOf(heure);
            if( d_d != -1 )
            if( data[p].hour_buffer[d_d+1] != undefined )//enlever dernier element en trop
            if( data[p].end_flag.indexOf(heure) == -1)//Si end_flag on étant pas
            {
                var index   = 0;

                //Calcule extension celulles
                var d_fin = data[p].hour_buffer[d_d+1];
                if(d_fin != undefined )
                {
                    //calculer la fin
                    do{
                        index++;
                        ok = true;

                        if( tab_hour[i+index] == undefined )
                            ok = false;

                        if( d_fin == tab_hour[i+index] )
                            ok = false;
                    }while( ok );
                    data[p].rowspan = index;
                }
                celulle.rowspan     = data[p].rowspan
                celulle.essai       = data[p].trial
                celulle.cessai      = data[p].ctrial
                celulle.extend      = index
                ok = true;
            }

            if(ok==false)
            {
                if(data[p].rowspan == undefined)
                    tab_buffer.td.push(celulle)
                else
                {
                    if( data[p].rowspan < 2 )
                        tab_buffer.td.push(celulle)
                    data[p].rowspan--;
                }
            }
            else
                tab_buffer.td.push(celulle)
        }
        //add
        ret_html.body.push(tab_buffer)
    }



    //Etape 6: Fermeture
    for( i=0; i<data.length; i++ )//machine
    for( p=0; p<data[i].close.length; p++ )//hour
    {
        let d_ferm = (new Date('2000/01/01 '+data[i].close[p].hour+':00')).getTime();

        //fermer les cases
        for( let c=0; c<data[i].hour.length; c++)
        {
            let machine =  data[i].uid
            let hour    = data[i].hour[c].hour
            var verif   = (new Date('2000/01/01 '+hour+':00')).getTime();
            ok      = false
            switch(data[i].close[p].ma)
            {
                //matin
                case -1:
                    if( verif <= d_ferm )
                        ok=true
                break;

                //aprem
                case 1:
                    if( verif >= d_ferm )
                        ok=true
                break;

                //journée
                case 0:
                    ok=true
                break;
            }
            if(ok)
                TabChangeValue( hour,  machine, 'fermeture', 1, ret_html.body )
        }
    }


    //Etape 7: Afficher réservation
    for( i=0; i<data.length; i++ )//machine
    for( p=0; p<data[i].booking.length; p++ )//booking
    {
        var booking = data[i].booking[p];
        var booking_date = booking.date
        //console.log( booking.date );
        //var booking_date = new Date( booking.date )
        //var booking_date = Utils.TimeZoneAdjust( booking.date );
        //console.log( sbooking_date );

        //Si type block
        if(booking.type == 'b')
            TabChangeValue( Fdate.getHeure(booking_date),  booking.machine, 'lock', booking.uid, ret_html.body )
        //Affichage info membre normal
        else
            TabPushValue( Fdate.getHeure(booking_date),  booking.machine, 'member', {"member":booking.member,"uid":booking.uid,"type":booking.type}, ret_html.body )//TODO ADD MEMBER
    }

    //Return object final
    return ret_html
}

function TabChangeValue( hour, machine, var_name, value, tab )
{
    let phour = FindHour(hour, tab)
    if( phour != null )
    {
        let posi = FindMachine(machine, tab[phour].td)
        if(posi!=null)
            tab[phour].td[posi][var_name] = value
    }
}
function TabPushValue( hour, machine, var_name, value, tab )
{
    let phour = FindHour(hour, tab)
    if( phour != null )
    {
        let posi = FindMachine(machine, tab[phour].td)
        if(posi!=null)
            tab[phour].td[posi][var_name].push( value )
    }
}

function FindHour( hour, tab ){
    for(var i=0; i<tab.length; i++)
    if( tab[i].hour == hour )
        return i
    return null
}
function FindMachine( machine, tab ){
    for(var i=0; i<tab.length; i++)
    if( tab[i].machine == machine )
        return i
    return null
}
</script>